var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative z4 w-100"},[_c('div',{staticClass:"c-datatable-header d-flex justify-content-between mb-7 px-2"},[_vm._m(0),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn btn-error d-block font-weight-bolder px-4 py-1 mt-2 mr-2",staticStyle:{"border-radius":"8px"},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2",staticStyle:{"border-radius":"8px"},on:{"click":function($event){_vm.setNewData(_vm.dataTemp);
          _vm.close();}}},[_vm._v(" Simpan ")])])]),_c('div',{staticClass:"c-datatable"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataTemp,"search":_vm.search,"items-per-page":_vm.perPage,"page":_vm.page,"height":"40vh","fixed-header":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-1 pl-2 pr-2",model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.jenis_kelamin",fn:function(ref){
          var item = ref.item;
return [_c('b-dropdown',{attrs:{"size":"sm","variant":"link","menu-class":"px-0 text-primary","toggle-class":"py-0 px-0 w-100","left":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-1 pl-2 pr-2",attrs:{"disabled":""},model:{value:(item.jenis_kelamin),callback:function ($$v) {_vm.$set(item, "jenis_kelamin", $$v)},expression:"item.jenis_kelamin"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){item.jenis_kelamin = 'laki-laki'}}},[_vm._v("Laki-Laki")]),_c('b-dropdown-item',{on:{"click":function($event){item.jenis_kelamin = 'perempuan'}}},[_vm._v("Perempuan")])],1)]}},{key:"item.nama_instansi",fn:function(ref){
          var item = ref.item;
return [_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-1 pl-2 pr-2",model:{value:(item.nama_instansi),callback:function ($$v) {_vm.$set(item, "nama_instansi", $$v)},expression:"item.nama_instansi"}})]}},{key:"item.email",fn:function(ref){
          var item = ref.item;
return [_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-1 pl-2 pr-2",model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}},{key:"item.hp",fn:function(ref){
          var item = ref.item;
return [_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-1 pl-2 pr-2",model:{value:(item.hp),callback:function ($$v) {_vm.$set(item, "hp", $$v)},expression:"item.hp"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"c-datatable-actions d-flex"},[(_vm.dataTemp.length > 1)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-delete",on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/cancel-icon.svg")}})]):_vm._e()])]}}],null,true)})],1),_c('v-divider'),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"btn btn-secondary d-block font-weight-bolder px-4 py-1 mt-2",staticStyle:{"border-radius":"8px"},on:{"click":function($event){_vm.dataTemp = _vm.dataTemp.concat( [{
          name: null,
          jenis_kelamin: 'laki-laki',
          hp: null,
          email: null,
          nama_instansi: null
        }]
      )}}},[_vm._v(" Tambah Peserta ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"title"},[_vm._v("Daftar Peserta")])])}]

export { render, staticRenderFns }