<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-2">
      <div class="d-flex justify-content-end">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>

      <button
        @click="dialog = true"
        class="btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2"
        style="border-radius: 8px"
        v-ripple
      >
        Tambah Laporan
      </button>
    </div>

    <div class="c-datatable">
      <v-data-table
        style="height:100%"
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        height="50vh"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.from_date`]="{ item }">
          {{ item.from_date }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="d-flex mt-2 mb-2">
            <div
              :key="`tolak-${item.status}`"
              class="not-completed-btn"
              :style="
                item.status == null || item.status == 0
                  ? 'background: #ff630b; color: #FFFFFF'
                  : 'background: #FFFFFF; color: #ff630b'
              "
              v-ripple
            >
              Not Completed
            </div>
            <div
              :key="`izinkan-${item.status}`"
              class="completed-btn"
              :style="
                item.status == 1
                  ? 'background: #1ba9e8; color: #FFFFFF'
                  : 'background: #FFFFFF; color: #1ba9e8'
              "
              v-ripple
            >
              Completed
            </div>
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="
                selectedData = item;
                dialogView = true;
              "
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                dialog = true;
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id_content)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="800px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  dialog = false;
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.id_content ? "Edit Laporan" : "Tambah Laporan"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger"></div>

                <b-row>
                  <b-col cols="12" md="3">
                    <div class="desc text-primary">{{ model.name_folder }}</div>
                    <div
                      :style="{
                        backgroundImage: `url('${model.upload_file}')`
                      }"
                      style="background-size: contain; background-position: top center;"
                    >
                      <b-aspect aspect="1:1" class="w-100"> </b-aspect>
                    </div>
                  </b-col>

                  <b-col cols="12" md="9">
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <div class="input-title">Nama Konsultan</div>
                          <b-form-input
                            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                            v-model="form.name_consultant"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group>
                          <div class="input-title">Job Desc</div>
                          <b-form-input
                            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                            v-model="form.jobdesc"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group>
                          <div class="input-title">Level Kegiatan</div>
                          <RoleSelect
                            v-if="dialog"
                            :model="form.level_id"
                            :data="arrLevelKegiatan"
                            placeholder="Pilih Level Kegiatan"
                            :selected="{
                              id: form.id_level || form.level_id,
                              name: form.id_level
                                ? arrLevelKegiatan[form.id_level - 1].name
                                : form.level_id
                            }"
                            :onSelected="
                              val => {
                                form.level_id = val.id;
                                form.id_level = val.id;
                              }
                            "
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6" :key="form.level_id">
                        <b-form-group>
                          <div class="input-title">Provinsi</div>
                          <RoleSelect
                            :disabled="form.level_id != 2 && form.level_id != 3"
                            v-if="dialog"
                            :model="form.province_id"
                            :data="arrProvince"
                            placeholder="Pilih Provinsi"
                            :label="'name_province'"
                            :itemid="'id_province'"
                            :selected="{
                              id: form.province_id,
                              name_province: form.province_name
                            }"
                            :onSelected="
                              val => {
                                form.province_id = val.id_province;
                                form.district_id = null;
                                getWilayah(
                                  `api/district/get?id_province=${val.id_province}`,
                                  'arrDistrict'
                                );
                              }
                            "
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6" :key="'kab-' + form.province_id">
                        <b-form-group>
                          <div class="input-title">Kabupaten/Kota</div>
                          <RoleSelect
                            :disabled="form.level_id != 3"
                            :model="form.district_id"
                            :data="arrDistrict"
                            placeholder="Pilih Kabupaten/Kota"
                            :label="'name_district'"
                            :itemid="'id_district'"
                            :selected="{
                              id: form.district_id,
                              name_district: form.district_name
                            }"
                            :onSelected="
                              val => {
                                form.district_id = val.id_district;
                                getWilayah(
                                  `api/subdistrict/get?id_district=${val.id_district}`,
                                  'arrSubDistrict'
                                );
                              }
                            "
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group>
                          <div class="input-title">Tanggal Mulai</div>
                          <date-picker
                            v-model="form.from_date"
                            :format="'YYYY-MM-DD'"
                            valueType="format"
                            placeholder="YYYY-MM-DD"
                            class="w-100"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group>
                          <div class="input-title">Tanggal Selesai</div>
                          <date-picker
                            v-model="form.to_date"
                            :format="'YYYY-MM-DD'"
                            valueType="format"
                            placeholder="YYYY-MM-DD"
                            class="w-100"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <v-divider></v-divider>
                <div class="desc text-primary mb-2">Berkas</div>

                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">ToR</div>
                      <v-file-input
                        v-model="form.tor"
                        truncate-length="30"
                        accept=".pdf"
                      ></v-file-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Penawaran</div>
                      <v-file-input
                        v-model="form.penawaran"
                        truncate-length="30"
                        accept=".pdf"
                      ></v-file-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <v-divider></v-divider>
                <div class="desc text-primary mb-2">Deliverables</div>

                <template v-for="(item, index) in form.arrDeliverable">
                  <div :key="'deliverable-' + index">
                    <div class="d-flex align-items-center w-100">
                      <b-form-group class="flex-grow-1">
                        <div class="input-title">Laporan</div>
                        <v-file-input
                          v-model="item.laporan"
                          truncate-length="30"
                          accept=".pdf"
                        ></v-file-input>
                      </b-form-group>

                      <b-form-group class="flex-grow-1">
                        <div class="input-title">Invoice</div>
                        <v-file-input
                          v-model="item.invoice"
                          truncate-length="30"
                          accept=".pdf"
                        ></v-file-input>
                      </b-form-group>

                      <b-form-group class="flex-grow-1">
                        <div class="input-title">Presentasi</div>
                        <v-file-input
                          v-model="item.presentasi"
                          truncate-length="30"
                          accept=".pdf"
                        ></v-file-input>
                      </b-form-group>

                      <v-btn
                        color="#E32910"
                        class="ml-4"
                        depressed
                        @click="deleteDeliverable(item)"
                      >
                        <img
                          :src="`${baseAppPath}/media/cancel-icon.svg`"
                          width="14px"
                          height="14px"
                          style="filter: brightness(999)"
                        />
                      </v-btn>
                    </div>

                    <v-divider></v-divider>
                  </div>
                </template>

                <button
                  @click.stop.prevent="
                    form.arrDeliverable = [
                      ...form.arrDeliverable,
                      { id: 0, laporan: null, invoice: null, presentasi: null }
                    ]
                  "
                  class="btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Tambah Deliverable
                </button>

                <v-divider></v-divider>
                <div class="desc text-primary mb-2">Final Result</div>

                <b-form-group>
                  <template v-for="(item, index) in form.arrFinal_result">
                    <div
                      :key="'presentaation-' + index"
                      class="d-flex align-items-center"
                    >
                      <b-form-input
                        v-model="item.judul"
                        placeholder="Judul laporan"
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7 mr-1"
                      ></b-form-input>

                      <v-file-input
                        v-model="item.upload_file"
                        style="width: 50%"
                        truncate-length="15"
                        accept=".pdf"
                      ></v-file-input>

                      <v-btn
                        color="#E32910"
                        class="ml-4"
                        depressed
                        @click="deleteFinalResult(item)"
                      >
                        <img
                          :src="`${baseAppPath}/media/cancel-icon.svg`"
                          width="14px"
                          height="14px"
                          style="filter: brightness(999)"
                        />
                      </v-btn>
                    </div>
                  </template>
                  <button
                    @click.stop.prevent="
                      form.arrFinal_result = [
                        ...form.arrFinal_result,
                        { id: 0, judul: null, upload_file: null }
                      ]
                    "
                    class="btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2"
                    style="border-radius: 100px"
                    v-ripple
                  >
                    Tambah Final Result
                  </button>
                </b-form-group>

                <v-divider></v-divider>
                <div class="desc text-primary mb-2">Status</div>

                <div class="d-flex">
                  <div
                    :key="`tolak-${form.status}`"
                    class="not-completed-btn"
                    :style="
                      form.status == null || form.status == 0
                        ? 'background: #ff630b; color: #FFFFFF'
                        : 'background: #FFFFFF; color: #ff630b'
                    "
                    @click="() => (form.status = 0)"
                    v-ripple
                  >
                    Not Completed
                  </div>
                  <div
                    :key="`izinkan-${form.status}`"
                    class="completed-btn"
                    :style="
                      form.status == 1
                        ? 'background: #1ba9e8; color: #FFFFFF'
                        : 'background: #FFFFFF; color: #1ba9e8'
                    "
                    @click="() => (form.status = 1)"
                    v-ripple
                  >
                    Completed
                  </div>
                </div>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialogView ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialogView"
        width="800px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  dialogView = false;
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <b-row>
                <b-col cols="12" md="3">
                  <div class="desc text-primary">{{ model.name_folder }}</div>
                  <div
                    :style="{ backgroundImage: `url('${model.upload_file}')` }"
                    style="background-size: contain; background-position: top center;"
                  >
                    <b-aspect aspect="1:1" class="w-100"> </b-aspect>
                  </div>
                </b-col>

                <b-col cols="12" md="9">
                  <div class="h3 mt-8">{{ selectedData.name_consultant }}</div>
                  <div class="h6">
                    {{ selectedData.from_date }} - {{ selectedData.to_date }}
                  </div>
                  <div
                    v-if="selectedData.status == 0"
                    class="not-completed-btn"
                    style="border-radius: 6px !important"
                    :style="
                      selectedData.status == null || selectedData.status == 0
                        ? 'background: #ff630b; color: #FFFFFF'
                        : 'background: #FFFFFF; color: #ff630b'
                    "
                    v-ripple
                  >
                    Not Completed
                  </div>
                  <div
                    v-if="selectedData.status == 1"
                    class="completed-btn"
                    style="border-radius: 6px !important"
                    :style="
                      selectedData.status == 1
                        ? 'background: #1ba9e8; color: #FFFFFF'
                        : 'background: #FFFFFF; color: #1ba9e8'
                    "
                    v-ripple
                  >
                    Completed
                  </div>
                </b-col>
              </b-row>

              <v-divider></v-divider>

              <div class="d-flex mb-2">
                <div
                  class="d-flex flex-grow-1 mb-2"
                  :style="{ opacity: selectedData.tor ? 1 : 0.5 }"
                >
                  <div
                    class="flex-grow-1 bg-secondary text-light py-1 px-2"
                    style="border-radius: 4px"
                  >
                    <div class="mt-1">ToR</div>
                  </div>
                  <div>
                    <v-btn
                      color="#236240"
                      depressed
                      class="p-0 mx-2 mr-4"
                      @click="selectedData.tor && openFile(selectedData.tor)"
                    >
                      <img
                        :src="`${baseAppPath}/media/Download.svg`"
                        width="20px"
                        height="20px"
                        style="filter: invert(1)"
                      />
                    </v-btn>
                  </div>
                </div>

                <div
                  class="d-flex flex-grow-1 mb-2"
                  :style="{ opacity: selectedData.penawaran ? 1 : 0.5 }"
                >
                  <div
                    class="flex-grow-1 bg-secondary text-light py-1 px-2"
                    style="border-radius: 4px"
                  >
                    <div class="mt-1">Penawaran</div>
                  </div>
                  <div>
                    <v-btn
                      color="#236240"
                      depressed
                      class="p-0 mx-2 mr-4"
                      @click="
                        selectedData.penawaran &&
                          openFile(selectedData.penawaran)
                      "
                    >
                      <img
                        :src="`${baseAppPath}/media/Download.svg`"
                        width="20px"
                        height="20px"
                        style="filter: invert(1)"
                      />
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-divider></v-divider>

              <div class="col-12 mb-2 text-primary">Deliverable</div>
              <template v-for="(pres, n) in selectedData.arrDeliverable">
                <div class="d-flex mb-2" v-bind:key="'p' + n">
                  <div
                    class="d-flex flex-grow-1 mb-2"
                    :style="{ opacity: pres.laporan ? 1 : 0.5 }"
                  >
                    <div
                      class="flex-grow-1 bg-secondary text-light py-1 px-2"
                      style="border-radius: 4px"
                    >
                      <div class="mt-1">Laporan</div>
                    </div>
                    <div>
                      <v-btn
                        color="#236240"
                        depressed
                        class="p-0 mx-2 mr-4"
                        @click="pres.laporan && openFile(pres.laporan)"
                      >
                        <img
                          :src="`${baseAppPath}/media/Download.svg`"
                          width="20px"
                          height="20px"
                          style="filter: invert(1)"
                        />
                      </v-btn>
                    </div>
                  </div>

                  <div
                    class="d-flex flex-grow-1 mb-2"
                    :style="{ opacity: pres.invoice ? 1 : 0.5 }"
                  >
                    <div
                      class="flex-grow-1 bg-secondary text-light py-1 px-2"
                      style="border-radius: 4px"
                    >
                      <div class="mt-1">Invoice</div>
                    </div>
                    <div>
                      <v-btn
                        color="#236240"
                        depressed
                        class="p-0 mx-2 mr-4"
                        @click="pres.invoice && openFile(pres.invoice)"
                      >
                        <img
                          :src="`${baseAppPath}/media/Download.svg`"
                          width="20px"
                          height="20px"
                          style="filter: invert(1)"
                        />
                      </v-btn>
                    </div>
                  </div>

                  <div
                    class="d-flex flex-grow-1 mb-2"
                    :style="{ opacity: pres.presentasi ? 1 : 0.5 }"
                  >
                    <div
                      class="flex-grow-1 bg-secondary text-light py-1 px-2"
                      style="border-radius: 4px"
                    >
                      <div class="mt-1">Presentasi</div>
                    </div>
                    <div>
                      <v-btn
                        color="#236240"
                        depressed
                        class="p-0 mx-2 mr-4"
                        @click="pres.presentasi && openFile(pres.presentasi)"
                      >
                        <img
                          :src="`${baseAppPath}/media/Download.svg`"
                          width="20px"
                          height="20px"
                          style="filter: invert(1)"
                        />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </template>

              <v-divider></v-divider>

              <div class="col-12 mb-2 text-primary">Final Result</div>
              <div class="col-sm-12">
                <template v-for="(pres, n) in selectedData.arrFinal_result">
                  <div
                    class="col-12 row mb-2"
                    v-if="pres.upload_file"
                    v-bind:key="'p' + n"
                  >
                    <div
                      class="col-11 bg-secondary text-light py-1"
                      style="border-radius: 4px"
                    >
                      <div class="mt-1">{{ pres.judul }}</div>
                    </div>
                    <div class="col-1">
                      <v-btn
                        color="#236240"
                        depressed
                        @click="openFile(pres.upload_file)"
                      >
                        <img
                          :src="`${baseAppPath}/media/Download.svg`"
                          width="20px"
                          height="20px"
                          style="filter: invert(1)"
                        />
                      </v-btn>
                      <!-- <q-btn class="ml-1 ph" icon="download" unelevated size="sm" color="primary" @click="openLink(pres.file)" /> -->
                    </div>
                  </div>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss">
.c-datatable-check {
  .custom-control-label::before,
  .custom-control-label::after {
    margin-top: -5px;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}

.v-table__overflow {
  height: 99.9%;
  max-height: 99.9%;
  overflow-y: auto !important;
}
</style>
<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}

.not-completed-btn {
  padding: 6px 20px;
  width: 130px;
  text-align: center;
  border: 1px solid #ff630b;
  border-right: 0px;
  border-radius: 6px 0px 0px 6px;
  cursor: pointer;
}
.completed-btn {
  padding: 6px 20px;
  width: 130px;
  text-align: center;
  border: 1px solid #1ba9e8;
  border-left: 0px;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
}
</style>

<script>
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";
import DatePicker from "vue2-datepicker";

export default {
  mixins: [validationMixin],
  props: ["programId", "folderId", "model"],
  components: {
    RoleSelect,
    DatePicker
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nama Konsultan", value: "name_consultant", width: "35%" },
        // { text: 'Deliverable', value: 'arrDeliverable', width: '150px' },
        // { text: 'Final Result', value: 'arrFinal_result', width: '130px' },
        { text: "Tanggal Mulai", value: "from_date", width: "130px" },
        { text: "Tanggal Selesai", value: "to_date", width: "130px" },
        { text: "Status", value: "status", width: "270px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: this.model?.arrContent || [],
      selectedData: {},

      form: {
        level_id: null,
        id_level: null,
        arrDeliverable: [],
        arrFinal_result: [],
        status: 0,
        from_date: null,
        to_date: null
      },

      arrLevelKegiatan: [
        { id: 1, name: "Nasional" },
        { id: 2, name: "Provinsi" },
        { id: 3, name: "Kabupaten/Kota" }
      ],
      arrProvince: [],
      arrDistrict: [],
      arrSubDistrict: [],
      arrVillage: [],

      loading: true,
      page: 1,
      perPage: 9999,
      pagination: {
        pageCount: 1
      },

      errors: [],
      dialog: false,
      dialogView: false
    };
  },
  validations: {
    form: {}
  },
  mounted() {
    this.getData();
    this.getWilayah("api/province/get", "arrProvince");
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData() {
      ApiService.get(
        `api/folder/laporan/detail?token=${JwtService.getToken(
          "program"
        )}&id_program=${this.programId}&id_folder=${this.folderId}`,
        "program"
      )
        .then(({ data }) => {
          if (data?.response?.arrFolderLaporan[0]?.arrContent) {
            this.data = data?.response?.arrFolderLaporan[0]?.arrContent;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWilayah(url, arrName) {
      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this[arrName] = data?.response?.[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let formData = new FormData();

      formData.append("token", JwtService.getToken("program"));
      formData.append("id_program", this.programId);
      formData.append("id_folder", this.folderId);
      formData.append("id_content", this.selectedData?.id_content || 0);
      formData.append("name_consultant", this.form.name_consultant);
      formData.append("jobdesc", this.form.jobdesc);
      formData.append("id_level", this.form.id_level);
      formData.append("from_date", this.form.from_date);
      formData.append("to_date", this.form.to_date);
      formData.append("status", this.form.status);

      formData.append("id_level", this.form.id_level);

      if (this.form.id_level == 3) {
        formData.append(`district_id`, this.form.district_id);
      }

      if (this.form.id_level == 2 || this.form.id_level == 3) {
        formData.append(`province_id`, this.form.province_id);
      }

      if (this.form.tor !== null && typeof this.form.tor !== "string") {
        formData.append(`tor`, this.form.tor);
      }
      if (
        this.form.penawaran !== null &&
        typeof this.form.penawaran !== "string"
      ) {
        formData.append(`penawaran`, this.form.penawaran);
      }

      for (let i = 0; i < this.form.arrDeliverable.length; i++) {
        if (
          this.form.arrDeliverable[i].laporan !== null &&
          typeof this.form.arrDeliverable[i].laporan !== "string"
        ) {
          formData.append(
            `arrDeliverable[${i}][id]`,
            this.form.arrDeliverable[i].id
          );
          formData.append(
            `arrDeliverable[${i}][laporan]`,
            this.form.arrDeliverable[i].laporan
          );
        }
        if (
          this.form.arrDeliverable[i].invoice !== null &&
          typeof this.form.arrDeliverable[i].invoice !== "string"
        ) {
          formData.append(
            `arrDeliverable[${i}][id]`,
            this.form.arrDeliverable[i].id
          );
          formData.append(
            `arrDeliverable[${i}][invoice]`,
            this.form.arrDeliverable[i].invoice
          );
        }
        if (
          this.form.arrDeliverable[i].presentasi !== null &&
          typeof this.form.arrDeliverable[i].presentasi !== "string"
        ) {
          formData.append(
            `arrDeliverable[${i}][id]`,
            this.form.arrDeliverable[i].id
          );
          formData.append(
            `arrDeliverable[${i}][presentasi]`,
            this.form.arrDeliverable[i].presentasi
          );
        }
      }

      for (let i = 0; i < this.form.arrFinal_result.length; i++) {
        formData.append(
          `arrFinal_result[${i}][id]`,
          this.form.arrFinal_result[i].id
        );
        formData.append(
          `arrFinal_result[${i}][judul]`,
          this.form.arrFinal_result[i].judul
        );

        if (
          this.form.arrFinal_result[i].upload_file !== null &&
          typeof this.form.arrFinal_result[i].upload_file !== "string"
        ) {
          formData.append(
            `arrFinal_result[${i}][upload_file]`,
            this.form.arrFinal_result[i].upload_file
          );
        }
      }

      if (!this?.selectedData?.id_content) {
        // Create
        ApiService.post(`api/folder/laporan/content`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.dialog = false;
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Laporan berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.post(`api/folder/laporan/content`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.dialog = false;
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Laporan berhasil diperbarui"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },

    deleteFinalResult(item) {
      this.form.arrFinal_result.splice(
        this.form.arrFinal_result.indexOf(item),
        1
      );
    },
    deleteDeliverable(item) {
      this.form.arrDeliverable.splice(
        this.form.arrDeliverable.indexOf(item),
        1
      );
    },

    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Laporan?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/folder/laporan/content?token=${JwtService.getToken(
                  "program"
                )}&id_program=${_this.programId}&id_folder=${
                  _this.folderId
                }&id_content=${id}`,
                "program"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        level_id: null,
        id_level: null,
        arrDeliverable: [],
        arrFinal_result: [],
        status: 0,
        from_date: null,
        to_date: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    },

    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    openFile(item) {
      window.open(item, "_blank");
    }
  }
};
</script>
