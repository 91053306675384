<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-2">
      <div class="d-flex justify-content-end">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>

      <button
        @click="dialog = true"
        class="btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2"
        style="border-radius: 8px"
        v-ripple
      >
        Tambah Dokumen
      </button>
    </div>

    <div class="c-datatable">
      <v-data-table
        style="height:100%"
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        height="50vh"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="openFile(item.upload_file)"
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                dialog = true;
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id_content)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="500px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  dialog = false;
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.id_content ? "Edit Dokumen" : "Tambah Dokumen"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger"></div>

                <b-form-group>
                  <div class="input-title">Nama File</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.name_content"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Nama Folder is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">File</div>
                  <b-form-file
                    placeholder="Pilih File"
                    class="c-file-input-2 form-control form-control-solid h-auto pl-3 pr-7 pb-0"
                    v-model="form.upload_file"
                    accept=".pdf"
                  ></b-form-file>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss">
.c-datatable-check {
  .custom-control-label::before,
  .custom-control-label::after {
    margin-top: -5px;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}

.v-table__overflow {
  height: 99.9%;
  max-height: 99.9%;
  overflow-y: auto !important;
}
</style>
<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [validationMixin],
  props: ["programId", "folderId", "model"],
  components: {},
  data() {
    return {
      search: "",
      headers: [
        { text: "Nama File", value: "name_content", width: "100%" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: this.model?.arrContent || [],
      selectedData: {},

      form: {},

      loading: true,
      page: 1,
      perPage: 9999,
      pagination: {
        pageCount: 1
      },

      errors: [],
      dialog: false
    };
  },
  validations: {
    form: {}
  },
  mounted() {
    this.getData();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData() {
      ApiService.get(
        `api/folder/dokumen/detail?token=${JwtService.getToken(
          "program"
        )}&id_program=${this.programId}&id_folder=${this.folderId}`,
        "program"
      )
        .then(({ data }) => {
          if (data?.response?.arrFolderDokumen[0]?.arrContent) {
            this.data = data?.response?.arrFolderDokumen[0]?.arrContent;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let formData = new FormData();

      formData.append("id_program", this.programId);
      formData.append("id_folder", this.folderId);
      formData.append("id_content", this.selectedData?.id_content || 0);
      formData.append("name", this.form.name_content);
      formData.append("token", JwtService.getToken("program"));

      if (typeof this.form.upload_file !== "string") {
        formData.append(`upload_file`, this.form.upload_file);
      }

      if (!this?.selectedData?.id_content) {
        // Create
        ApiService.post(`api/folder/dokumen/content`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.dialog = false;
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Dokumen berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.post(`api/folder/dokumen/content`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.dialog = false;
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Dokumen berhasil diperbarui"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },

    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Dokumen?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/folder/dokumen/content?token=${JwtService.getToken(
                  "program"
                )}&id_program=${_this.programId}&id_folder=${
                  _this.folderId
                }&id_content=${id}`,
                "program"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {};

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    },

    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    openFile(item) {
      window.open(item, "_blank");
    }
  }
};
</script>
