<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <div class="per-page-wrapper">
          <b-dropdown
            size="sm"
            variant="link"
            left
            class="pl-2 pr-6"
            style="width: 120px;"
            no-flip
          >
            <template v-slot:button-content>
              <div class="mx-6">{{ produkTab[produkActiveIndex] }}</div>
            </template>

            <b-dropdown-item @click="changeProdukTab(0)"
              >Publikasi</b-dropdown-item
            >
            <b-dropdown-item @click="changeProdukTab(1)"
              >Laporan</b-dropdown-item
            >
            <b-dropdown-item @click="changeProdukTab(2)"
              >Dokumen</b-dropdown-item
            >
            <b-dropdown-item @click="changeProdukTab(3)"
              >Administrasi</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="per-page-wrapper mr-4">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="arrCategory"
        :sort-by.sync="columnName"
        :sort-desc.sync="isDescending"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div class="c-max-lines-1">
            {{ item.name }}
          </div>
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <div class="c-max-lines-1">
            {{ strippedContent(item.desc) }}
          </div>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <div>{{ item.active == 1 ? "Aktif" : "Nonaktif" }}</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="!trashActive" class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>

          <div v-if="trashActive" class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="restoreData(item.id)"
            >
              <img :src="`${baseAppPath}/media/reply-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="400px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.name
                    ? "Edit Kategori Publikasi"
                    : "Tambah Kategori Publikasi"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Nama</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.name"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Name is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}

.video-title {
  margin: 20px 0px;
  font-size: 15px;
  font-weight: bold;
}
.video-desc {
  font-size: 15px;
  color: #8c8c8c;
}
</style>

<script>
import { validationMixin } from "vuelidate";
// import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [validationMixin],
  props: [
    "programId",
    "produkActiveIndex",
    "produkTab",
    "changeProdukTab",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {},
  data() {
    return {
      search: "",
      columnName: "created_at",
      isDescending: true,
      headers: [
        { text: "Kategori Publikasi", value: "name", width: "100%" },
        // { text: 'Ordering', value: 'ordering', width: '100px' },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      arrCategory: [],
      selectedData: {},

      form: {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        url_video: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showVideoDialog: false,
      trashActive: false
    };
  },
  validations: {
    form: {
      // token: {
      //   required,
      //   minLength: minLength(1)
      // },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let url = `api/produk/get?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.programId}`;

      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.arrCategory) {
            this.arrCategory = data?.response?.arrCategory;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let formData = {
        token: JwtService.getToken("program"),
        id_program: this.programId,
        arrCategory: [
          {
            id_category: this.selectedData?.id || 0,
            name: this.form.name,
            ordering: this.selectedData?.ordering || this.arrCategory.length + 1
          }
        ]
      };

      if (!this?.selectedData?.name) {
        // Create
        ApiService.post("api/produk-category/post", formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Kategori Publikasi berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.post(`api/produk-category/post`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Kategori Publikasi berhasil diperbarui"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Kategori Publikasi?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/produk-category/delete?token=${JwtService.getToken(
                  "program"
                )}&id_program=${_this.programId}&id_category=${id}`,
                "program"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        url_video: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
