<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <div class="per-page-wrapper">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable program-datatable">
      <v-data-table
        :headers="headers"
        :items="arrEvent"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.no`]="{ item }">
          {{ arrEvent.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.description`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ strippedContent(item.description) }}
          </div>
        </template>

        <template v-slot:[`item.summary`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ strippedContent(item.summary) }}
          </div>
        </template>

        <template v-slot:[`item.file_location`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ item.file_location }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-view"
              v-on:click="
                selectedData = item;
                showViewDialog = true;
              "
            >
              <img :src="`${baseAppPath}/media/view-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                openDialog();
                getKegiatanDetail();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id_event)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="900px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-6 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.name ? "Edit Kegiatan" : "Tambah Kegiatan" }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Nama Kegiatan</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.name_event"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Nama Kegiatan is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <div class="input-title">Jenis Kegiatan</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.jenis_event_id"
                        :data="arrJenisEvent"
                        placeholder="Pilih Jenis Kegiatan"
                        :selected="{
                          id: form.jenis_event_id,
                          name: form.jenis_event_name
                        }"
                        :onSelected="val => (form.jenis_event_id = val.id)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <div class="input-title">Outcome</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.id_komponen_kerja"
                        :data="arrKomponen_kerja"
                        placeholder="Pilih Outcome"
                        :selected="{
                          id: form.id_komponen_kerja,
                          name: form.name_komponen_kerja
                        }"
                        :onSelected="val => (form.id_komponen_kerja = val.id)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <div class="input-title">Level</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="arrLevel"
                        :data="arrLevel"
                        placeholder="Pilih Level"
                        :selected="{
                          id: form.level_event,
                          name: form.level_name
                        }"
                        :onSelected="val => (form.level_event = val.id)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <b-form-textarea
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.description"
                    rows="4"
                  ></b-form-textarea>
                </b-form-group>

                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Tanggal Kegiatan</div>
                      <div class="d-flex align-items-center">
                        <date-picker
                          v-model="form.event_date"
                          :format="'YYYY-MM-DD'"
                          valueType="format"
                          placeholder="YYYY-MM-DD"
                        ></date-picker>
                        <div class="mx-3 font-weight-bolder">to</div>
                        <date-picker
                          v-model="form.event_to_date"
                          :format="'YYYY-MM-DD'"
                          valueType="format"
                          placeholder="YYYY-MM-DD"
                        ></date-picker>
                      </div>

                      <b-form-invalid-feedback id="input-4-live-feedback">
                        Durasi is required.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Lokasi/Gedung</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.address"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Provinsi</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.province_id"
                        :data="arrProvince"
                        placeholder="Pilih Provinsi"
                        :label="'name_province'"
                        :itemid="'id_province'"
                        :selected="{
                          id: form.province_id,
                          name_province: form.province_name
                        }"
                        :onSelected="
                          val => {
                            form.province_id = val.id_province;
                            getWilayah(
                              `api/district/get?id_province=${val.id_province}`,
                              'arrDistrict'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.province_id">
                      <div class="input-title">Kabupaten/Kota</div>
                      <RoleSelect
                        :model="form.district_id"
                        :data="arrDistrict"
                        placeholder="Pilih Kabupaten/Kota"
                        :label="'name_district'"
                        :itemid="'id_district'"
                        :selected="{
                          id: form.district_id,
                          name_district: form.district_name
                        }"
                        :onSelected="
                          val => {
                            form.district_id = val.id_district;
                            getWilayah(
                              `api/subdistrict/get?id_district=${val.id_district}`,
                              'arrSubDistrict'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.district_id">
                      <div class="input-title">Kecamatan</div>
                      <RoleSelect
                        :model="form.subdistrict_id"
                        :data="arrSubDistrict"
                        placeholder="Pilih Kecamatan"
                        :label="'name_subdistrict'"
                        :itemid="'id_district'"
                        :selected="{
                          id: form.subdistrict_id,
                          name_subdistrict: form.subdistrict_name
                        }"
                        :onSelected="
                          val => {
                            form.subdistrict_id = val.id_subdistrict;
                            getWilayah(
                              `api/village/get?id_subdistrict=${val.id_subdistrict}`,
                              'arrVillage'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.subdistrict_id">
                      <div class="input-title">Kelurahan</div>
                      <RoleSelect
                        :model="form.village_id"
                        :data="arrVillage"
                        placeholder="Pilih Desa/Kelurahan"
                        :label="'name_village'"
                        :itemid="'id_village'"
                        :selected="{
                          id: form.village_id,
                          name_village: form.village_name
                        }"
                        :onSelected="
                          val => {
                            form.village_id = val.id_village;
                          }
                        "
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="pt-6">
                    <l-map
                      v-if="dialog"
                      style="height: 406px"
                      :zoom="maps.zoom"
                      :center="maps.center"
                    >
                      <l-tile-layer :url="maps.url"></l-tile-layer>
                      <l-marker :lat-lng.sync="maps.position" draggable>
                        <l-icon
                          :icon-size="[15, 15]"
                          :icon-url="`${baseAppPath}/media/circle-blue.png`"
                        />
                      </l-marker>
                    </l-map>
                  </b-col>
                </b-row>

                <v-divider></v-divider>

                <b-row>
                  <b-col cols="12" md="6">
                    <button
                      @click.stop.prevent="showViewDialogPeserta = true"
                      class="btn btn-secondary w-100 d-block font-weight-bolder px-12 py-2 mb-3 font-size-3"
                      style="border-radius: 8px"
                      v-ripple
                    >
                      Input Daftar Hadir
                    </button>

                    <b-form-group>
                      <div class="input-title">Laki-laki</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_laki_laki"
                        type="number"
                        disabled
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Perempuan</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_perempuan"
                        type="number"
                        disabled
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <v-divider></v-divider>

                    <b-form-group>
                      <div class="input-title">Remaja</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_youth"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Dewasa</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_dewasa"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <v-divider></v-divider>

                    <b-form-group>
                      <div class="input-title">Disabilitas</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_disabilitas"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Non-Disabilitas</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_non_disabilitas"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Pemerintah Pusat</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_pemerintah_pusat"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Pemerintah Daerah</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_pemerintah_daerah"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">LSM</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_lsm"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Komunitas</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_komunitas"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Perusahaan Swasta</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_perusahaan_swasta"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Perguruan Tinggi</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_perguruan_tinggi"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Lembaga Internasional</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.participant_lembaga_internasional"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <v-divider></v-divider>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group>
                      <div class="input-title">ID Card</div>
                      <v-file-input
                        v-model="form.card_id"
                        show-size
                        truncate-length="15"
                      ></v-file-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group>
                      <div class="input-title">ToR</div>
                      <v-file-input
                        v-model="form.tor"
                        show-size
                        truncate-length="15"
                      ></v-file-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group>
                      <div class="input-title">Notula</div>
                      <v-file-input
                        v-model="form.notula"
                        show-size
                        truncate-length="15"
                      ></v-file-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group>
                  <div class="input-title">Link Video</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.link_youtube"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Presentasi</div>
                  <template v-for="(item, index) in form.arrPresentation">
                    <div
                      :key="'presentaation-' + index"
                      class="d-flex align-items-center"
                    >
                      <b-form-input
                        v-model="item.name_presente"
                        placeholder="Nama Pemateri"
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7 mr-1"
                      ></b-form-input>

                      <v-file-input
                        v-model="item.file_presentation"
                        :show-size="false"
                        style="width: 50%"
                        truncate-length="15"
                      ></v-file-input>

                      <v-btn
                        color="#E32910"
                        class="ml-4"
                        depressed
                        @click="deletePresentation(item)"
                      >
                        <img
                          :src="`${baseAppPath}/media/cancel-icon.svg`"
                          width="14px"
                          height="14px"
                          style="filter: brightness(999)"
                        />
                      </v-btn>
                    </div>
                  </template>
                  <button
                    @click.stop.prevent="
                      form.arrPresentation = [
                        ...form.arrPresentation,
                        {
                          id_presentation: 0,
                          file_presentation: null,
                          name_presente: null
                        }
                      ]
                    "
                    class="btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2"
                    style="border-radius: 100px"
                    v-ripple
                  >
                    Tambah Presentasi
                  </button>
                </b-form-group>

                <v-divider></v-divider>

                <b-form-group>
                  <div class="input-title">Ringkasan (Bahasa)</div>
                  <b-form-textarea
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.summary"
                    rows="4"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Summary (English)</div>
                  <b-form-textarea
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.summary_english"
                    rows="4"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-textarea>
                </b-form-group>

                <button
                  ref="kt_submit"
                  :disabled="loading"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showViewDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showViewDialog"
        width="800px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="() => (showViewDialog = false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <KegiatanModal
                ref="_kegiatanModal"
                :model="selectedData"
                :idProgram="programId"
                :idEvent="selectedData.id_event"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showViewDialogPeserta"
      class="overflow-hidden"
      :style="{ height: showViewDialogPeserta ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showViewDialogPeserta"
        width="850px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-4 px-4 pt-4">
              <KegiatanModalInputPeserta
                :data="form.arrAttendance"
                :setNewData="
                  newData => {
                    form.arrAttendance = newData;
                    form.participant_laki_laki = newData.filter(
                      el => el.jenis_kelamin == 'laki-laki'
                    ).length;
                    form.participant_perempuan = newData.filter(
                      el => el.jenis_kelamin == 'perempuan'
                    ).length;
                  }
                "
                :showInputPeserta="() => (showViewDialogPeserta = true)"
                :close="() => (showViewDialogPeserta = false)"
              />
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";
import DatePicker from "vue2-datepicker";

import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";
// import Editor from '@/view/components/Editor/Editor.vue';
import KegiatanModal from "./KegiatanModal.vue";
import KegiatanModalInputPeserta from "./KegiatanModalInputPeserta.vue";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);

export default {
  mixins: [validationMixin],
  props: [
    "programId",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    // Editor,
    KegiatanModal,
    KegiatanModalInputPeserta,
    RoleSelect,
    DatePicker
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no", width: "60px" },
        { text: "Nama Kegiatan", value: "name", width: "120px" },
        { text: "Jenis Kegiatan", value: "jenis_event_name", width: "120px" },
        { text: "Outcome", value: "name_komponen_kerja", width: "120px" },
        { text: "Level", value: "level_name", width: "120px" },
        { text: "Deskripsi", value: "description", width: "120px" },
        { text: "Tanggal Mulai", value: "event_date", width: "120px" },
        { text: "Tanggal Berakhir", value: "event_to_date", width: "120px" },
        { text: "Lokasi/Gedung", value: "location", width: "120px" },
        { text: "Provinsi", value: "province_name", width: "120px" },
        { text: "Kota/Kabupaten", value: "district_name", width: "120px" },
        { text: "Kecamatan", value: "subdistrict_name", width: "120px" },
        { text: "Desa/Kelurahan", value: "village_name", width: "120px" },
        { text: "Peserta", value: "desc", width: "120px" },
        { text: "ID Card", value: "card_id", width: "120px" },
        { text: "TOR", value: "tor", width: "120px" },
        { text: "Notula", value: "notula", width: "120px" },
        // { text: 'Foto', value: 'arrPhoto', width: '120px' },
        { text: "Video", value: "link_youtube", width: "120px" },
        { text: "Presentasi", value: "total_presentation", width: "120px" },
        // { text: 'Ringkasan', value: 'desc', width: '120px' },
        { text: "Summary", value: "summary", width: "120px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      arrEvent: [],
      arrLevel: [],
      arrJenisEvent: [],
      arrKomponen_kerja: [],
      arrJenisLembaga: [],

      arrProvince: [],
      arrDistrict: [],
      arrSubDistrict: [],
      arrVillage: [],

      arrAttendance: [
        {
          name: null,
          jenis_kelamin: "laki-laki",
          hp: null,
          email: null,
          nama_instansi: null
        }
      ],

      maps: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        icon: L.icon({
          iconUrl: "/assets/marker-icon.png"
          // iconSize: [37, 37],
          // iconAnchor: [16, 37]
        }),
        zoom: 4,
        center: [-1.9450816, 113.3737863],
        position: {
          lat: -1.9450816,
          lng: 113.3737863
        }
      },

      selectedData: {},
      form: {
        pelatihan_id: this.pelatihanId,

        name: null,

        file_location: null,
        thumbnail: null,

        id_program: null,
        name_event: null,
        jenis_event: null,
        jenis_event_id: null,
        description: null,

        id_komponen_kerja: null,
        id_level_event: null,
        description_event: null,
        address: null,
        latitude: null,
        longitude: null,
        id_province: null,
        id_district: null,
        id_subdistrict: null,
        id_village: null,
        province_id: null,
        district_id: null,
        subdistrict_id: null,
        village_id: null,
        summary: null,
        summary_english: null,
        arrAttendance: [],
        participant_laki_laki: 0,
        participant_perempuan: 0,
        participant_youth: 0,
        participant_dewasa: 0,
        participant_disabilitas: 0,
        participant_non_disabilitas: 0,
        participant_pemerintah_pusat: 0,
        participant_pemerintah_daerah: 0,
        participant_lsm: 0,
        participant_komunitas: 0,
        participant_perusahaan_swasta: 0,
        participant_perguruan_tinggi: 0,
        participant_lembaga_internasional: 0,
        event_date: null,
        event_to_date: null,
        link_youtube: null,
        arrPresentation: [
          {
            id_presentation: 0,
            file_presentation: null,
            name_presente: null
          }
        ],
        select: {}
      },

      select: {},

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false,
      showViewDialogPeserta: false
    };
  },
  validations: {
    form: {
      name_event: {
        required,
        minLength: minLength(1)
      }
    }
  },
  mounted() {
    this.getData();
    this.getWilayah("api/province/get", "arrProvince");
  },
  methods: {
    getData() {
      let url = `api/event/get?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.programId}`;

      ApiService.get(url, "program")
        .then(({ data }) => {
          // console.log(data)
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this.arrEvent = data.response.arrEvent;
            this.arrLevel = data.response.arrLevel;
            this.arrJenisEvent = data.response.arrJenisEvent;
            this.arrKomponen_kerja = data.response.arrKomponen_kerja;
            this.arrJenisLembaga = data.response.arrJenisLembaga;

            this.arrEvent.jenis_event_id = parseInt(
              data.response.arrEvent.jenis_event_id
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWilayah(url, arrName) {
      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this[arrName] = data?.response?.[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getKegiatanDetail() {
      let url = `api/event/get_detail?id_program=${
        this.selectedData?.id_program
      }&id_event=${this.selectedData?.id_event}&token=${JwtService.getToken(
        "program"
      )}`;
      ApiService.get(url, "program").then(({ data }) => {
        if (data?.response?.Code == 200 || data?.response?.code == 200) {
          // this.arrEvent = data.response.arrEvent;
          var res = data.response;
          this.form = { ...res.arrEvent, ...res.arrEvent.arrParticipant };

          // formating because detail and post attributes is different
          if (this?.form?.name) this.form.name_event = this?.form?.name;
          if (this?.form?.jenis_event_id)
            this.form.jenis_event = parseInt(this?.form?.jenis_event_id);
          if (this?.form?.id_komponen_kerja)
            this.form.id_komponen_kerja = parseInt(
              this?.form?.id_komponen_kerja
            );
          if (this?.form?.outcome_event_id)
            this.form.outcome_event = parseInt(this?.form?.outcome_event_id);
          if (this?.form?.description)
            this.form.description_event = this?.form?.description;
          if (this?.form?.level_event)
            this.form.id_level_event = this?.form?.level_event;
          if (this?.form?.location) this.form.address = this?.form?.location;
          if (this?.form?.address) this.form.address = this?.form?.location;

          this.select.jenis_event = res.arrJenisEvent;
          this.select.jenis_eventTmp = res.arrJenisEvent;
          this.select.outcome_event = res.arrKomponen_kerja;
          this.select.outcome_eventTmp = res.arrKomponen_kerja;
          this.select.level_event = res.arrLevel;
          this.select.level_eventTmp = res.arrLevel;

          var attendanceFix = [];
          for (const row of this.form.arrAttendance) {
            let id = 0;
            if (row.id_attendance !== undefined) id = row.id_attendance;
            row.id = id;
            attendanceFix.push(row);
          }
          this.form.arrAttendance = attendanceFix;

          var participant = this.form.arrParticipant;
          for (const key in participant) {
            this.form[key] = participant[key];
          }

          var photo = this.form.arrPhoto;
          if (photo.length < 5) {
            var arrPhoto = [];
            // eslint-disable-next-line no-const-assign
            for (let i = 0; i < 5; i++) {
              // console.log('photo', i)
              if (photo[i] !== undefined) {
                arrPhoto.push(photo[i]);
              } else {
                const file = { photo_file: null };
                arrPhoto.push(file);
              }
            }

            this.form.arrPhoto = arrPhoto;
          }

          var presentasiFix = [];
          for (const row of this.form.arrPresentation) {
            let id = 0;
            if (row.id_attendance !== undefined) id = row.id_attendance;
            row.id = id;
            row.file = row.file_presentation;
            presentasiFix.push(row);
          }
          this.form.arrPresentation = presentasiFix;

          this.maps.position.lat = this.form.latitude;
          this.maps.position.lng = this.form.longitude;
          this.maps.center = [this.form.latitude, this.form.longitude];
          this.form.select = this.select;
        }
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var formData = new FormData();
      formData.append("id_program", this.selectedData?.id_program);
      formData.append("id_event", this.selectedData?.id_event);
      formData.append("token", JwtService.getToken("program"));

      for (let key in this.form) {
        if (typeof this.form[key] === "object") {
          for (let subKey in this.form[key]) {
            formData.append(`${key}.${subKey}`, this.form[key][subKey]);
          }
        } else {
          formData.append(key, this.form[key]);
        }
      }

      if (!this?.selectedData?.name) {
        // Create
        ApiService.post("api/event/post", formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Kegiatan berhasil ditambahkan"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        formData.append("_method", "PATCH");
        ApiService.post(`api/event/update`, formData, "program")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Kegiatan berhasil diperbarui"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Kegiatan?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(
                `api/event/delete?id_program=${
                  _this.programId
                }&id_event=${id}&token=${JwtService.getToken("program")}`,
                "program"
              )
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    deletePresentation(item) {
      this.form.arrPresentation.splice(
        this.form.arrPresentation.indexOf(item),
        1
      );
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: null,
        province_id: null,
        district_id: null,
        subdistrict_id: null,
        village_id: null,
        arrPresentation: [
          {
            id_presentation: 0,
            file_presentation: null,
            name_presente: null
          }
        ]
      };
      this.arrDistrict = [];
      this.arrSubDistrict = [];
      this.arrVillage = [];

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    },
    openModule() {
      window.open(
        `${process.env.VUE_APP_ELEARNING_URL}${this.selectedData.file_location}`,
        "_blank"
      );
    },
    openLink(pres) {
      window.open(pres, "_blank");
    }
  }
};
</script>
