<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <div class="per-page-wrapper">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable program-datatable">
      <v-data-table
        :headers="headers"
        :items="arrBeneficeries"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.no`]="{ item }">
          {{ arrBeneficeries.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ strippedContent(item.desc) }}
          </div>
        </template>

        <template v-slot:[`item.file_location`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ item.file_location }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = {
                  ...form,
                  ...item,
                  file_location: null,
                  thumbnail: null
                };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="600px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.name
                    ? "Edit Penerima Manfaat"
                    : "Tambah Penerima Manfaat"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-form-group>
                  <div class="input-title">Nama Lembaga Penerima</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.nama_lembaga"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Nama Lembaga Penerima is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Jenis Lembaga</div>
                  <RoleSelect
                    v-if="dialog"
                    :model="form.id_jenis_lembaga"
                    :data="arrJenisLembaga"
                    placeholder="Pilih Jenis Lembaga"
                    :selected="{
                      id: form.id_jenis_lembaga,
                      name: form.jenis_lembaga
                    }"
                    :onSelected="val => (form.id_jenis_lembaga = val.id)"
                  />
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Tahun</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.tahun"
                    type="number"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Jenis Manfaat</div>
                  <RoleSelect
                    v-if="dialog"
                    :model="form.id_jenis_manfaat"
                    :data="arrJenisManfaat"
                    placeholder="Pilih Jenis Manfaat"
                    :selected="{
                      id: form.id_jenis_manfaat,
                      name: form.jenis_manfaat
                    }"
                    :onSelected="val => (form.id_jenis_manfaat = val.id)"
                  />
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Nominal</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="form.nilai"
                    type="number"
                    aria-describedby="input-1-live-feedback"
                  ></b-form-input>
                </b-form-group>

                <v-divider></v-divider>

                <div class="title text-primary">
                  Penerima Manfaat Berdasarkan Jenis Kelamin
                </div>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Laki-Laki</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.laki_laki"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Perempuan</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.perempuan"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <v-divider></v-divider>

                <div class="title text-primary">
                  Penerima Manfaat Berdasarkan Usia
                </div>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Youth</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.youth"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Dewasa</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.dewasa"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <v-divider></v-divider>

                <div class="title text-primary">
                  Penerima Manfaat Berdasarkan Disabilitas
                </div>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Non-disabilitas</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.non_disabilitas"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <div class="input-title">Disabilitas</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.disabilitas"
                        type="number"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <button
                  ref="kt_submit"
                  :disabled="loading"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
// import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [validationMixin],
  props: [
    "programId",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    RoleSelect
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no", width: "60px" },
        { text: "Nama Lembaga", value: "nama_lembaga", width: "120px" },
        { text: "Jenis Lembaga", value: "jenis_lembaga", width: "120px" },
        { text: "Tahun", value: "tahun", width: "120px" },
        { text: "Jenis Manfaat", value: "jenis_manfaat", width: "120px" },
        { text: "Nominal", value: "nilai", width: "120px" },
        { text: "Laki-Laki", value: "laki_laki", width: "120px" },
        { text: "Perempuan", value: "perempuan", width: "120px" },
        { text: "Youth", value: "youth", width: "120px" },
        { text: "Dewasa", value: "dewasa", width: "120px" },
        { text: "Disabilitas", value: "disabilitas", width: "120px" },
        { text: "Non-disabilitas", value: "non_disabilitas", width: "120px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      arrBeneficeries: [],
      arrJenisManfaat: [],
      arrJenisLembaga: [],
      selectedData: {},

      form: {
        // pelatihan_id: this.pelatihanId,
        // name: null,
        // desc: null,
        // file_location: null,
        // thumbnail: null,
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false
    };
  },
  validations: {
    form: {
      // name: {
      //   required,
      //   minLength: minLength(1)
      // },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let url = `api/bnf/get?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.programId}`;

      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this.arrBeneficeries = data.response.arrBeneficeries;
            this.arrJenisManfaat = data.response.arrJenisManfaat;
            this.arrJenisLembaga = data.response.arrJenisLembaga;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var form = {
        ...this.form,
        jenis_lembaga: this.form.id_jenis_lembaga,
        jenis_manfaat: this.form.id_jenis_manfaat
      };
      var formData = {
        id_program: this.programId,
        arrBeneficeries: [form],
        beneficeries: form
      };

      if (!this?.selectedData?.id_beneficeries) {
        // Create
        ApiService.post(
          `api/program-data/post?token=${JwtService.getToken("program")}`,
          formData,
          "program"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Penerima Manfaat berhasil ditambahkan"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.patch(
          `api/program-data/update?token=${JwtService.getToken("program")}`,
          formData,
          "program"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Penerima Manfaat berhasil diperbarui"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Penerima Manfaat?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`api/modul/${id}`, "program")
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    restoreData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan memulihkan Penerima Manfaat?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.post(`api/modul/restore/${id}`, null, "program")
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        pelatihan_id: this.pelatihanId,
        name: null,
        desc: null,
        file_location: null,
        thumbnail: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
