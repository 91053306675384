<template>
  <div>
    <v-card elevation="0" class="row text-primary">
      <div
        class="col-12 col-sm-6 text-h6 bold"
        @click="getData(dataModel.id_program, dataModel.id_event)"
      >
        Kegiatan
      </div>
      <div class="col-12 col-sm-6 right">
        <!-- <q-btn v-if="(program.created_by === user.id) || allowAllProgram(user.email, program.id_program)" @click="edit" unelevated size="sm" outline class="capital" color="primary" icon="edit" label="Ubah Kegiatan" />
        <q-btn v-if="(program.created_by === user.id) || allowAllProgram(user.email, program.id_program)" @click="deleteData(dataModel)" unelevated size="sm" outline class="capital ml" color="primary" icon="delete" label="Hapus Kegiatan" /> -->
        <!-- <q-btn @click="formConfig.show = false" flat size="sm" outline class="capital pl-1" dense color="grey" icon="close"  /> -->
      </div>
      <div class="col-12 bold pt-2 pb-1" v-html="dataModel.name"></div>
      <div
        class="col-12 text-primary mb-6"
        v-html="dataModel.description"
      ></div>

      <b-row>
        <b-col cols="12" md="6" class="mb-6">
          <div class="col-12" v-html="dataModel.jenis_event_name"></div>
          <div class="col-12" v-html="dataModel.outcome_event_name"></div>
          <div class="col-12">Level : {{ dataModel.level_name }}</div>

          <div class="col-12 mt-2">
            Waktu : {{ dataModel.event_date }} - {{ dataModel.event_to_date }}
          </div>

          <div class="col-12 mt-2">Tempat : {{ dataModel.location }}</div>
          <div class="col-12 bold">{{ dataModel.village_name }}</div>
          <div class="col-12 bold">{{ dataModel.subdistrict_name }}</div>
          <div class="col-12 bold">{{ dataModel.district_name }}</div>
          <div class="col-12 bold">{{ dataModel.province_name }}</div>
        </b-col>

        <b-col cols="12" md="6">
          <div
            class="bg-orange-9 text-light pv-1 ph-1 mh-1"
            v-if="maps.position.lat === null"
          >
            <q-icon name="info" /> Maps not loaded because "Latitude" is NULL
          </div>
          <div
            class="bg-orange-9 text-light pv-1 ph-1 mh-1"
            v-if="maps.position.lng === null"
          >
            <q-icon name="info" /> Maps not loaded because "Longitude" is NULL
          </div>

          <l-map
            v-if="maps.position.lat !== null && maps.position.lng !== null"
            @ready="onMapsReady()"
            style="height: 340px"
            :zoom="maps.zoom"
            :center="maps.center"
          >
            <l-tile-layer :url="maps.url"></l-tile-layer>
            <l-marker :lat-lng.sync="maps.position">
              <l-icon
                :icon-size="[15, 15]"
                icon-url="/assets/circle-blue.png"
              />
            </l-marker>
          </l-map>
        </b-col>
      </b-row>

      <!-- <div class="col-12 col-sm-6 row pt-2 pr-2">
        <div class="col-12 mt-2" >
          <q-btn class="ml-1" :color="(dataModel.card_id) ? 'primary' : 'grey-6'" unelevated @click="openLink(dataModel.card_id)" label="ID Card" />
          <q-btn class="ml-1" :color="(dataModel.tor) ? 'primary' : 'grey-6'" unelevated @click="openLink(dataModel.tor)" label="ToR" />
          <q-btn class="ml-1" :color="(dataModel.notula) ? 'primary' : 'grey-6'" unelevated @click="openLink(dataModel.notula)" label="Notula" />
        </div>
      </div> -->

      <div class="col-12 mh-2 pv"><v-divider /></div>

      <div class="col-12 pb-3">
        <div class="bold text-h6 ">Ringkasan</div>
        <div v-html="dataModel.summary || '-'"></div>
      </div>

      <div class="col-12 pb-3">
        <div class="bold text-h6 ">Summary</div>
        <div v-html="dataModel.summary_english || '-'"></div>
      </div>

      <div class="col-12 mh-2 pv"><v-divider /></div>

      <div class="col-12 mb-4">
        <div class="bold text-h6 ">Peserta</div>
        <div>
          <q-table
            flat
            :data="dataModel.arrAttendance"
            :virtual-scroll-item-size="48"
            :rows-per-page-options="[10]"
            :columns="[
              { name: 'name', label: 'Provinsi', field: 'name', align: 'left' },
              {
                name: 'jenis_kelamin',
                label: 'Jenis kelamin',
                field: 'jenis_kelamin',
                align: 'left'
              },
              {
                name: 'nama_instansi',
                label: 'Nama Instansi',
                field: 'nama_instansi',
                align: 'left'
              },
              { name: 'email', label: 'Email', field: 'email', align: 'left' },
              { name: 'hp', label: 'Nomor Telpon', field: 'hp', align: 'left' }
            ]"
            row-key="name"
            no-data-label="Data Belum tersedia"
            class="shadow-N"
          />
        </div>
      </div>

      <div class="col-12">
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-space-between">
              <div>Laki-laki</div>
              <div>Perempuan</div>
            </div>
            <div class="d-flex">
              <div style="height: 14px; width: 20px; background: #236240"></div>

              <div class="d-flex flex-grow-1 mx-2">
                <div
                  :style="{
                    flex: participant.participant_laki_laki,
                    height: '14px',
                    background: '#236240'
                  }"
                ></div>
                <div
                  :style="{
                    flex: participant.participant_perempuan,
                    height: '14px',
                    background: '#18d06b'
                  }"
                ></div>
              </div>

              <div style="height: 14px; width: 20px; background: #18d06b"></div>
            </div>

            <div class="d-flex align-items-center justify-space-between mt-4">
              <div>Remaja</div>
              <div>Dewasa</div>
            </div>
            <div class="d-flex">
              <div style="height: 14px; width: 20px; background: #236240"></div>

              <div class="d-flex flex-grow-1 mx-2">
                <div
                  :style="{
                    flex: participant.participant_youth,
                    height: '14px',
                    background: '#236240'
                  }"
                ></div>
                <div
                  :style="{
                    flex: participant.participant_dewasa,
                    height: '14px',
                    background: '#18d06b'
                  }"
                ></div>
              </div>

              <div style="height: 14px; width: 20px; background: #18d06b"></div>
            </div>

            <div class="d-flex align-items-center justify-space-between mt-4">
              <div>Non-Disabilitas</div>
              <div>Disabilitas</div>
            </div>
            <div class="d-flex">
              <div style="height: 14px; width: 20px; background: #236240"></div>

              <div class="d-flex flex-grow-1 mx-2">
                <div
                  :style="{
                    flex: participant.participant_non_disabilitas,
                    height: '14px',
                    background: '#236240'
                  }"
                ></div>
                <div
                  :style="{
                    flex: participant.participant_disabilitas,
                    height: '14px',
                    background: '#18d06b'
                  }"
                ></div>
              </div>

              <div style="height: 14px; width: 20px; background: #18d06b"></div>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center mt-2">
              <v-progress-linear
                color="#236240"
                :value="
                  getSingleLinear(dataModel, 'participant_pemerintah_pusat')
                "
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">Pemerintah Pusat</div>
            </div>

            <div class="d-flex align-items-center">
              <v-progress-linear
                color="#236240"
                :value="
                  getSingleLinear(dataModel, 'participant_pemerintah_daerah')
                "
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">Pemerintah Daerah</div>
            </div>

            <div class="d-flex align-items-center">
              <v-progress-linear
                color="#236240"
                :value="getSingleLinear(dataModel, 'participant_lsm')"
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">LSM</div>
            </div>

            <div class="d-flex align-items-center">
              <v-progress-linear
                color="#236240"
                :value="getSingleLinear(dataModel, 'participant_komunitas')"
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">Komunitas</div>
            </div>

            <div class="d-flex align-items-center">
              <v-progress-linear
                color="#236240"
                :value="
                  getSingleLinear(dataModel, 'participant_perusahaan_swasta')
                "
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">Perusahaan Swasta</div>
            </div>

            <div class="d-flex align-items-center">
              <v-progress-linear
                color="#236240"
                :value="
                  getSingleLinear(dataModel, 'participant_perguruan_tinggi')
                "
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">Perguruan Tinggi</div>
            </div>

            <div class="d-flex align-items-center">
              <v-progress-linear
                color="#236240"
                :value="
                  getSingleLinear(
                    dataModel,
                    'participant_lembaga_internasional'
                  )
                "
                reverse
              ></v-progress-linear>

              <div class="ml-2" style="width: 200px">Lembaga Internasional</div>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Chart -->

      <div class="col-12 mh-2 pv"><v-divider /></div>

      <div class="col-12 text-h6 bold mb-2">Files</div>
      <div class="col-12 mb-2">Photo</div>

      <div v-if="dataModel.arrPhoto.length === 0">-</div>

      <div class="AllCont" v-if="dataModel.arrPhoto.length !== 0">
        <div
          class="ContQuint"
          v-for="(img, n) in dataModel.arrPhoto"
          v-bind:key="n"
        >
          <v-img
            max-height="250"
            max-width="250"
            :src="
              img.url === null || img.url === undefined
                ? '/contents/noimg.png'
                : img.url
            "
          ></v-img>
          <!-- <q-img style="border-radius:10px;" :ratio="4/3"
             :src="(img.url === null || img.url === undefined) ? '/contents/noimg.png' : img.url" >
              <template v-slot:error>
                <div class="absolute-full flex flex-center bg-negative text-white">
                  Cannot load image
                </div>
              </template>
            </q-img> -->
        </div>
      </div>

      <div class="col-12 mb-2 mt-2">Video</div>

      <div class="col-sm-12">
        <div v-if="!dataModel.link_youtube">-</div>

        <div class="mb-2 mt-2" v-if="dataModel.link_youtube">
          <div class="video-container">
            <iframe
              class="video-responsive-iframe"
              :src="dataModel.link_youtube"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="col-12 mb-2">Presentasi</div>
      <div class="col-sm-12">
        <div
          class="col-12 row mb-2"
          v-for="(pres, n) in dataModel.arrPresentation"
          v-bind:key="'p' + n"
        >
          <div
            class="col-11 bg-secondary text-light py-1"
            style="border-radius: 4px"
          >
            <div class="mt-1">{{ pres.name_presente }}</div>
          </div>
          <div class="col-1">
            <v-btn color="#236240" depressed @click="openLink(pres.file)">
              <img
                :src="`${baseAppPath}/media/Download.svg`"
                width="20px"
                height="20px"
                style="filter: invert(1)"
              />
            </v-btn>
            <!-- <q-btn class="ml-1 ph" icon="download" unelevated size="sm" color="primary" @click="openLink(pres.file)" /> -->
          </div>
        </div>
      </div>
    </v-card>

    <!-- <q-dialog v-model="toRemove.modal" persistent>
      <q-card class="pv-2 ph-2">
        <v-card class="row items-center">
          <div class="text-center">
            <span class="q-ml-sm bold">Hapus Kegiatan</span> <br>
            <span class="q-ml-sm">Anda yakin ingin menghapus kegiatan ini?</span>
          </div>
        </v-card>

        <q-card-actions align="center">
          <q-btn color="grey-7" class="pv-1 capital" unelevated label="Batal" v-close-popup />
          <q-btn color="primary" class="pv-1 capital" unelevated label="Ya" @click="remove(toRemove.data)"/>
        </q-card-actions>
      </q-card>
    </q-dialog> -->
  </div>
</template>

<style>
.AllCont {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.ContQuint {
  display: inline-block;
  width: 150px;
  padding: 10px;
  border-radius: 5px;
}

.fix-progress {
  margin-top: 0;
  position: relative;
}

.title-progres {
  position: relative !important;
  bottom: 6px !important;
}

.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 75%; /* 4:3 Aspect Ratio */
}

.video-responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.lbl-presentasi {
  position: relative;
  top: 7px;
  padding-left: 10px;
}
</style>

<script>
import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import Meta from "./meta";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);

export default {
  name: "DetailKegiatan",
  // components: { FormAtendance, Modal },
  props: ["idProgram", "idEvent", "model", "formConfig"],
  data() {
    return {
      Meta,
      API: this.$Api,
      // default data
      smLinear: "12px",
      dataModel: Meta.model,
      participant: null,
      maps: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        icon: L.icon({
          iconUrl: "/assets/circle-blue.png"
          // iconSize: [37, 37],
          // iconAnchor: [16, 37]
        }),
        zoom: 9,
        center: [-1.9450816, 113.3737863],
        position: {
          lat: -1.9450816,
          lng: 113.3737863
        }
      },
      toRemove: {
        modal: false,
        data: null
      },
      select: {}
    };
  },

  created() {
    //
  },

  mounted() {
    this.dataModel = this.model;
    this.maps.position.lat = this.dataModel.latitude;
    this.maps.position.lng = this.dataModel.longitude;
    this.maps.center = [this.dataModel.latitude, this.dataModel.longitude];
    this.getData(this.dataModel.id_program, this.dataModel.id_event);
  },

  updated() {
    // console.log(this.model)
  },

  methods: {
    onMapsReady() {
      // console.log('maps is ready')
    },

    getData() {
      // var endpoint = `https://pi-dev.co.id/kalfor/be/pi/api/event/get_detail?id_program=${this.idProgram}&id_event=${this.idEvent}`

      let url = `api/event/get_detail?id_program=${this.idProgram}&id_event=${
        this.idEvent
      }&token=${JwtService.getToken("program")}`;
      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            var res = data.response;
            this.dataModel = res.arrEvent;
            this.participant = res.arrEvent.arrParticipant;

            // formating because detail and post attributes is different
            if (this.dataModel.name)
              this.dataModel.name_event = this.dataModel.name;
            if (this.dataModel.jenis_event_id)
              this.dataModel.jenis_event = parseInt(
                this.dataModel.jenis_event_id
              );
            if (this.dataModel.id_komponen_kerja)
              this.dataModel.id_komponen_kerja = parseInt(
                this.dataModel.id_komponen_kerja
              );
            if (this.dataModel.outcome_event_id)
              this.dataModel.outcome_event = parseInt(
                this.dataModel.outcome_event_id
              );
            if (this.dataModel.description)
              this.dataModel.description_event = this.dataModel.description;
            if (this.dataModel.level_event)
              this.dataModel.id_level_event = this.dataModel.level_event;
            if (this.dataModel.location)
              this.dataModel.address = this.dataModel.location;
            if (this.dataModel.address)
              this.dataModel.address = this.dataModel.location;

            this.select.jenis_event = res.arrJenisEvent;
            this.select.jenis_eventTmp = res.arrJenisEvent;
            this.select.outcome_event = res.arrKomponen_kerja;
            this.select.outcome_eventTmp = res.arrKomponen_kerja;
            this.select.level_event = res.arrLevel;
            this.select.level_eventTmp = res.arrLevel;

            var attendanceFix = [];
            for (const row of this.dataModel.arrAttendance) {
              let id = 0;
              if (row.id_attendance !== undefined) id = row.id_attendance;
              row.id = id;
              attendanceFix.push(row);
            }
            this.dataModel.arrAttendance = attendanceFix;

            var participant = this.dataModel.arrParticipant;
            for (const key in participant) {
              this.dataModel[key] = participant[key];
            }

            var photo = this.dataModel.arrPhoto;
            if (photo.length < 5) {
              var arrPhoto = [];
              // eslint-disable-next-line no-const-assign
              for (let i = 0; i < 5; i++) {
                // console.log('photo', i)
                if (photo[i] !== undefined) {
                  arrPhoto.push(photo[i]);
                } else {
                  const file = { photo_file: null };
                  arrPhoto.push(file);
                }
              }

              this.dataModel.arrPhoto = arrPhoto;
            }

            var presentasiFix = [];
            for (const row of this.dataModel.arrPresentation) {
              let id = 0;
              if (row.id_attendance !== undefined) id = row.id_attendance;
              row.id = id;
              row.file = row.file_presentation;
              presentasiFix.push(row);
            }
            this.dataModel.arrPresentation = presentasiFix;

            this.maps.position.lat = this.dataModel.latitude;
            this.maps.position.lng = this.dataModel.longitude;
            this.maps.center = [
              this.dataModel.latitude,
              this.dataModel.longitude
            ];
            this.dataModel.select = this.select;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    edit() {
      // this.formConfig.show = false
      // if (this.formConfig.callback !== null || this.formConfig.callback !== undefined) this.formConfig.callback(this.dataModel)
    },

    deleteData(data) {
      this.toRemove.modal = true;
      this.toRemove.data = data;
    },

    remove(data) {
      let endpoint = "https://pi-dev.co.id/kalfor/be/pi/api/event/delete";
      endpoint += `?id_event=${data.id_event}`;
      endpoint += `&id_program=${data.id_program}`;
      // this.API.delete(endpoint, (status, data, message, response, full) => {
      // this.API.delete(endpoint, (status, response) => {
      this.API.delete(endpoint, status => {
        if (status === 200) {
          // var res = response.response
          // console.log(res)
          this.toRemove.modal = false;
          // if (this.formConfig.callbackDelete !== null || this.formConfig.callbackDelete !== undefined) this.formConfig.callbackDelete()
        }
      });
    },

    getValLinear(base1, base2) {
      var totalData = base1 + base2;
      var val1 = base1 / totalData;

      return val1;
    },

    getSingleLinear(dataModel, attr) {
      var total = 0;
      total = total + dataModel?.arrParticipant?.participant_pemerintah_pusat;
      total = total + dataModel?.arrParticipant?.participant_pemerintah_daerah;
      total = total + dataModel?.arrParticipant?.participant_lsm;
      total = total + dataModel?.arrParticipant?.participant_komunitas;
      total = total + dataModel?.arrParticipant?.participant_perusahaan_swasta;
      total = total + dataModel?.arrParticipant?.participant_perguruan_tinggi;
      total =
        total + dataModel?.arrParticipant?.participant_lembaga_internasional;

      var res = (parseFloat(dataModel[attr]) / total) * 100;

      return res;
    },

    openLink(pres) {
      window.open(pres, "_blank");
    }
  }
};
</script>
