<template>
  <div class="position-relative z4 w-100">
    <div class="c-datatable-header d-flex justify-content-between mb-7 px-2">
      <div class="d-flex justify-content-end">
        <div class="title">Daftar Peserta</div>
      </div>

      <div class="d-flex justify-content-end">
        <button
          @click="close"
          class="btn btn-error d-block font-weight-bolder px-4 py-1 mt-2 mr-2"
          style="border-radius: 8px"
          v-ripple
        >
          Batal
        </button>

        <button
          @click="
            setNewData(dataTemp);
            close();
          "
          class="btn btn-primary d-block font-weight-bolder px-4 py-1 mt-2"
          style="border-radius: 8px"
          v-ripple
        >
          Simpan
        </button>
      </div>
    </div>

    <div class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="dataTemp"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        height="40vh"
        fixed-header
        hide-default-footer
        dense
      >
        <template v-slot:[`item.name`]="{ item }">
          <b-form-input
            class="form-control form-control-solid h-auto py-1 pl-2 pr-2"
            v-model="item.name"
          ></b-form-input>
        </template>

        <template v-slot:[`item.jenis_kelamin`]="{ item }">
          <b-dropdown
            size="sm"
            variant="link"
            menu-class="px-0 text-primary"
            toggle-class="py-0 px-0 w-100"
            left
            no-flip
          >
            <template v-slot:button-content>
              <b-form-input
                class="form-control form-control-solid h-auto py-1 pl-2 pr-2"
                disabled
                v-model="item.jenis_kelamin"
              ></b-form-input>
            </template>

            <b-dropdown-item @click="item.jenis_kelamin = 'laki-laki'"
              >Laki-Laki</b-dropdown-item
            >
            <b-dropdown-item @click="item.jenis_kelamin = 'perempuan'"
              >Perempuan</b-dropdown-item
            >
          </b-dropdown>
        </template>

        <template v-slot:[`item.nama_instansi`]="{ item }">
          <b-form-input
            class="form-control form-control-solid h-auto py-1 pl-2 pr-2"
            v-model="item.nama_instansi"
          ></b-form-input>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <b-form-input
            class="form-control form-control-solid h-auto py-1 pl-2 pr-2"
            v-model="item.email"
          ></b-form-input>
        </template>

        <template v-slot:[`item.hp`]="{ item }">
          <b-form-input
            class="form-control form-control-solid h-auto py-1 pl-2 pr-2"
            v-model="item.hp"
          ></b-form-input>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-if="dataTemp.length > 1"
              v-ripple
              class="c-datatable-delete"
              @click="deleteRow(item)"
            >
              <img :src="`${baseAppPath}/media/cancel-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>
    </div>

    <v-divider></v-divider>

    <button
      @click="
        dataTemp = [
          ...dataTemp,
          {
            name: null,
            jenis_kelamin: 'laki-laki',
            hp: null,
            email: null,
            nama_instansi: null
          }
        ]
      "
      class="btn btn-secondary d-block font-weight-bolder px-4 py-1 mt-2"
      style="border-radius: 8px"
      v-ripple
    >
      Tambah Peserta
    </button>
  </div>
</template>

<style lang="scss">
.c-datatable-check {
  .custom-control-label::before,
  .custom-control-label::after {
    margin-top: -5px;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}
</style>
<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: ["showInputPeserta", "close", "data", "setNewData"],
  components: {},
  data() {
    return {
      search: "",
      headers: [
        { text: "Nama", value: "name", width: "19%" },
        { text: "Jenis Kelamin", value: "jenis_kelamin", width: "19%" },
        { text: "Instansi", value: "nama_instansi", width: "19%" },
        { text: "Email", value: "email", width: "19%" },
        { text: "Nomor Telepon", value: "hp", width: "19%" },
        { text: "", value: "actions", sortable: false }
      ],
      dataTemp: [],
      dataModule: [],
      selectedData: {},
      selectedApp: {
        id: null,
        name: null
      },
      selectedRole: {
        id: null,
        name: null
      },

      form: {
        app_id: null,
        module_id: null,
        role_id: null,
        read: 1,
        create: 1,
        update: 1,
        delete: 1
      },

      loading: false,
      page: 1,
      perPage: 9999,
      pagination: {
        pageCount: 1
      },

      errors: []
    };
  },
  validations: {
    form: {
      app_id: {
        required
      },
      module_id: {
        required
      },
      role_id: {
        required
      },
      read: {
        required
      },
      create: {
        required
      },
      update: {
        required
      },
      delete: {
        required
      }
    }
  },
  mounted() {
    this.dataTemp = this.data;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    deleteRow(item) {
      this.dataTemp.splice(this.dataTemp.indexOf(item), 1);
    },
    resetForm() {
      this.form = {
        ...this.form,
        read: 1,
        create: 1,
        update: 1,
        delete: 1
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    },

    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    openTugas(path) {
      window.open(`${process.env.VUE_APP_ELEARNING_URL}${path}`, "_blank");
    }
  },
  computed: {
    ...mapState({
      errors: state => state.account.errors
    })
  }
};
</script>
