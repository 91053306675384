const Meta = {
  name: "Kegiatan",
  icon: "stop_circle",
  module: "kegiatan",
  topBarMenu: [],
  permission: {
    browse: true,
    create: true,
    update: true,
    delete: true,
    restore: true
  },
  model: {
    id_event: null,
    id_program: null,
    name_event: null,
    jenis_event: 0,
    id_komponen_kerja: 0, // outcome_event
    description_event: null,
    id_level_event: 0,
    address: null,
    latitude: "",
    longitude: "",
    id_province: 0,
    id_district: 0,
    id_subdistrict: 0,
    id_village: 0,
    summary: null,
    summary_english: null,
    created_by: 0,
    participant_laki_laki: 0,
    participant_perempuan: 0,
    participant_youth: 0,
    participant_dewasa: 0,
    participant_disabilitas: 0,
    participant_non_disabilitas: 0,
    participant_pemerintah_pusat: 0,
    participant_pemerintah_daerah: 0,
    participant_lsm: 0,
    participant_komunitas: 0,
    participant_perusahaan_swasta: 0,
    participant_perguruan_tinggi: 0,
    participant_lembaga_internasional: 0,
    event_date: null,
    event_to_date: null,
    tor: null,
    notula: null,
    card_id: null,
    link_youtube: "",
    arrAttendance: [
      // {
      //   id: 0,
      //   name: null,
      //   hp: null,
      //   email: null,
      //   jenis_kelamin: null,
      //   nama_instansi: null
      // }
    ],
    arrPhoto: [
      { photo_file: null },
      { photo_file: null },
      { photo_file: null },
      { photo_file: null },
      { photo_file: null }
    ],
    arrPresentation: [
      // {
      //   id: 0,
      //   name_presente: null,
      //   file: null
      // }
    ]
  }
};

export default Meta;
