<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <div class="per-page-wrapper">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable program-datatable">
      <v-data-table
        :headers="headers"
        :items="arrProgramData"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.desc`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ strippedContent(item.desc) }}
          </div>
        </template>

        <template v-slot:[`item.file_location`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ item.file_location }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                maps.position.lat = item.coor_latitude;
                maps.position.lng = item.coor_longitude;
                (maps.center = [item.coor_latitude, item.coor_longitude]),
                  (form = { ...form, ...item });
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="800px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.name
                    ? "Edit Data Program Input"
                    : "Tambah Data Program Input"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-row>
                  <b-col cols="12" md="5">
                    <b-form-group>
                      <div class="input-title">Lokasi</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.id_location"
                        :data="arrLocation"
                        placeholder="Pilih Lokasi"
                        :label="'district_name'"
                        :itemid="'id_province'"
                        :selected="{ id: form.id_location }"
                        :onSelected="val => (form.id_location = val.id)"
                      />
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Provinsi</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.province_id"
                        :data="arrProvince"
                        placeholder="Pilih Provinsi"
                        :label="'name_province'"
                        :itemid="'id_province'"
                        :selected="{
                          id: form.province_id,
                          name_province: form.province_name
                        }"
                        :onSelected="
                          val => {
                            form.province_id = val.id_province;
                            getWilayah(
                              `api/district/get?id_province=${val.id_province}`,
                              'arrDistrict'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.province_id">
                      <div class="input-title">Kabupaten/Kota</div>
                      <RoleSelect
                        :model="form.district_id"
                        :data="arrDistrict"
                        placeholder="Pilih Kabupaten/Kota"
                        :label="'name_district'"
                        :itemid="'id_district'"
                        :selected="{
                          id: form.district_id,
                          name_district: form.district_name
                        }"
                        :onSelected="
                          val => {
                            form.district_id = val.id_district;
                            getWilayah(
                              `api/subdistrict/get?id_district=${val.id_district}`,
                              'arrSubDistrict'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.district_id">
                      <div class="input-title">Kecamatan</div>
                      <RoleSelect
                        :model="form.subdistrict_id"
                        :data="arrSubDistrict"
                        placeholder="Pilih Kecamatan"
                        :label="'name_subdistrict'"
                        :itemid="'id_district'"
                        :selected="{
                          id: form.subdistrict_id,
                          name_subdistrict: form.subdistrict_name
                        }"
                        :onSelected="
                          val => {
                            form.subdistrict_id = val.id_subdistrict;
                            getWilayah(
                              `api/village/get?id_subdistrict=${val.id_subdistrict}`,
                              'arrVillage'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.subdistrict_id">
                      <div class="input-title">Kelurahan</div>
                      <RoleSelect
                        :model="form.village_id"
                        :data="arrVillage"
                        placeholder="Pilih Desa/Kelurahan"
                        :label="'name_village'"
                        :itemid="'id_village'"
                        :selected="{
                          id: form.village_id,
                          name_village: form.village_name
                        }"
                        :onSelected="
                          val => {
                            form.village_id = val.id_village;
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Polygon Number</div>
                      <b-form-input
                        v-model="form.poligon_number"
                        type="number"
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Latitude</div>
                      <b-form-input
                        v-model="maps.position.lat"
                        disabled
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Longitude</div>
                      <b-form-input
                        v-model="maps.position.lng"
                        disabled
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="7" class="pt-6">
                    <l-map
                      v-if="dialog"
                      style="height: 384px"
                      :zoom="maps.zoom"
                      :center="maps.center"
                    >
                      <l-tile-layer :url="maps.url"></l-tile-layer>
                      <l-marker :lat-lng.sync="maps.position" draggable>
                        <l-icon
                          :icon-size="[15, 15]"
                          :icon-url="`${baseAppPath}/media/circle-blue.png`"
                        />
                      </l-marker>
                    </l-map>
                  </b-col>

                  <b-col cols="12" md="12">
                    <b-form-group>
                      <div class="input-title">Landscape Name</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.landscape_name"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Total Area</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.total_area"
                        type="number"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Function Area</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.id_function_area"
                        :data="arrStatus_area"
                        placeholder="Pilih Function Area"
                        :label="'name'"
                        :itemid="'id'"
                        :selected="{
                          id: form.id_function_area,
                          name: form.status_function_area
                        }"
                        :onSelected="val => (form.id_function_area = val.id)"
                      />
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Landcover Class</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.id_landcover_class"
                        :data="arrLand_cover"
                        placeholder="Pilih Landcover Class"
                        :label="'name'"
                        :itemid="'id'"
                        :height="'100px'"
                        :selected="{
                          id: form.id_landcover_class,
                          name: form.landcover_class
                        }"
                        :onSelected="val => (form.id_landcover_class = val.id)"
                      />
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Peat Type</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.peat_type"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Peat Hydro</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.peat_hydro"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Permit Status</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.permit_status"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Flood Risk Rating</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.flood_risk_rating"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Land Risk Rating</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.landslide_risk_rating"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Piaps</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.piaps"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Tora Status</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.tora_status"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Eri Rank</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="form.eri_rank"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <button
                  ref="kt_submit"
                  :disabled="loading"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showViewDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showViewDialog"
        width="500px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="() => (showViewDialog = false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <b-row>
                <b-col cols="12" sm="3">
                  <div
                    :style="{
                      backgroundImage: `url('${baseAppPath}/media/logos/logo-letter-1.png')`
                    }"
                    style="background-size: contain; background-position: top center;"
                  >
                    <b-aspect aspect="1:1" class="w-100"> </b-aspect>
                  </div>
                </b-col>

                <b-col cols="12" sm="9">
                  <div class="desc mt-2 mb-2">{{ selectedData.name }}</div>
                  <div class="modal-desc" v-html="selectedData.desc"></div>

                  <button
                    @click="openModule()"
                    class="btn btn-primary d-block font-weight-bolder px-6 py-3 mt-5"
                    style="border-radius: 10px"
                    v-ripple
                  >
                    Lihat Modul
                  </button>
                </b-col>
              </b-row>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
// import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);

export default {
  mixins: [validationMixin],
  props: [
    "programId",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    RoleSelect
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Polygon Number", value: "poligon_number", width: "140px" },
        { text: "Longitude", value: "coor_longitude", width: "140px" },
        { text: "Latitude", value: "coor_latitude", width: "140px" },
        { text: "Landscape Name", value: "landscape_name", width: "140px" },
        { text: "Total Area (ha)", value: "total_area", width: "140px" },
        { text: "Regency", value: "district_name", width: "140px" },
        { text: "Subdistrict", value: "subdistrict_name", width: "140px" },
        { text: "Village", value: "village_name", width: "140px" },
        {
          text: "Function Area",
          value: "status_function_area",
          width: "140px"
        },
        { text: "Land Cover Class", value: "landcover_class", width: "140px" },
        { text: "Peat Type", value: "peat_type", width: "140px" },
        {
          text: "Peat Hydrological Unity",
          value: "peat_hydro",
          width: "140px"
        },
        { text: "Permit Status", value: "permit_status", width: "140px" },
        {
          text: "Flood Risk Rating",
          value: "flood_risk_rating",
          width: "140px"
        },
        {
          text: "Landslide Risk Rating",
          value: "landslide_risk_rating",
          width: "150px"
        },
        { text: "PIAP Status in 2019", value: "piaps", width: "140px" },
        { text: "TORA Status", value: "tora_status", width: "140px" },
        { text: "ERI Ranking", value: "eri_rank", width: "140px" },
        { text: "IDM Ranking in 2017", value: "idm_rank", width: "150px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      arrProgramData: [],
      arrStatus_area: [],
      arrLand_cover: [],
      arrLocation: [],
      selectedData: {},

      maps: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        icon: L.icon({
          iconUrl: "/assets/marker-icon.png"
          // iconSize: [37, 37],
          // iconAnchor: [16, 37]
        }),
        zoom: 4,
        center: [-1.9450816, 113.3737863],
        position: {
          lat: -1.9450816,
          lng: 113.3737863
        }
      },

      arrProvince: [],
      arrDistrict: [],
      arrSubDistrict: [],
      arrVillage: [],

      form: {
        // pelatihan_id: this.pelatihanId,
        province_id: null,
        district_id: null,
        subdistrict_id: null,
        village_id: null,
        name: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false
    };
  },
  validations: {
    form: {
      // pelatihan_id: {
      //   required,
      //   minLength: minLength(1)
      // },
    }
  },
  mounted() {
    this.getData();
    this.getWilayah("api/province/get", "arrProvince");
    this.getLocationData();
  },
  methods: {
    getData() {
      let url = `api/program-data/get?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.programId}`;

      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this.arrProgramData = data.response.arrProgramData;
            this.arrStatus_area = data.response.arrStatus_area;
            this.arrLand_cover = data.response.arrLand_cover;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWilayah(url, arrName) {
      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this[arrName] = data?.response?.[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLocationData() {
      let url = `api/program-location/get?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.programId}`;

      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this.arrLocation = data.response.arrLocation;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var form = {
        ...this.form,
        id_province: this.form.province_id,
        id_district: this.form.district_id,
        id_subdistrict: this.form.subdistrict_id,
        id_village: this.form.village_id,
        landcover_class: parseInt(this.form.id_landcover_class),
        status_function_area: parseInt(this.form.id_function_area),
        polygon_number: this.form.poligon_number
      };

      var formData = {
        id_program: this.programId,
        arrProgramData: [form],
        programData: form
      };
      // formData.append("id_program", this.programId);
      // formData.append("arrProgramData", JSON.stringify([ this.form ]));
      // formData.append("programData", this.form);

      if (!this?.selectedData?.id_data) {
        // Create
        ApiService.post(
          `api/program-data/post?token=${JwtService.getToken("program")}`,
          formData,
          "program"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Program Input berhasil ditambahkan"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.post(
          `api/program-data/post?token=${JwtService.getToken("program")}`,
          formData,
          "program"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Program Input berhasil diperbarui"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Program Input?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`api/modul/${id}`, "elearning")
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        province_id: null,
        district_id: null,
        subdistrict_id: null,
        village_id: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    },
    openModule() {
      window.open(
        `${process.env.VUE_APP_ELEARNING_URL}${this.selectedData.file_location}`,
        "_blank"
      );
    }
  }
};
</script>
