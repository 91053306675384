<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <div class="per-page-wrapper">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div class="c-datatable program-datatable">
      <v-data-table
        :headers="headers"
        :items="arrLocation"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.no`]="{ item }">
          {{ arrLocation.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ strippedContent(item.desc) }}
          </div>
        </template>

        <template v-slot:[`item.file_location`]="{ item }">
          <div class="text-truncate" style="width: 200px">
            {{ item.file_location }}
          </div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <!-- <div v-ripple class="c-datatable-view" v-on:click="selectedData = item; showViewDialog = true">
              <img :src="`${baseAppPath}/media/view-icon.svg`">
            </div> -->
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item, penanggung_jawab: item.location_pj };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="dialog"
        width="800px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.name
                    ? "Edit Lokasi Kegiatan"
                    : "Tambah Lokasi Kegiatan"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert fade alert-danger">
                  <!-- <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div> -->
                </div>

                <b-row>
                  <b-col cols="12" md="5">
                    <b-form-group>
                      <div class="input-title">Penanggung Jawab</div>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        v-model="$v.form.penanggung_jawab.$model"
                        :state="validateState('penanggung_jawab')"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>

                      <b-form-invalid-feedback id="input-1-live-feedback">
                        Penanggung Jawab is required.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Tanggal</div>
                      <date-picker
                        v-model="form.date"
                        :format="'YYYY-MM-DD'"
                        valueType="format"
                        placeholder="YYYY-MM-DD"
                        class="w-100"
                      >
                      </date-picker>

                      <b-form-invalid-feedback id="input-4-live-feedback">
                        Tanggal is required.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Provinsi</div>
                      <RoleSelect
                        v-if="dialog"
                        :model="form.province_id"
                        :data="arrProvince"
                        placeholder="Pilih Provinsi"
                        :label="'name_province'"
                        :itemid="'id_province'"
                        :selected="{
                          id: form.province_id,
                          name_province: form.province_name
                        }"
                        :onSelected="
                          val => {
                            form.province_id = val.id_province;
                            getWilayah(
                              `api/district/get?id_province=${val.id_province}`,
                              'arrDistrict'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group v-if="form.province_id">
                      <div class="input-title">Kabupaten/Kota</div>
                      <RoleSelect
                        :model="form.district_id"
                        :data="arrDistrict"
                        placeholder="Pilih Kabupaten/Kota"
                        :label="'name_district'"
                        :itemid="'id_district'"
                        :selected="{
                          id: form.district_id,
                          name_district: form.district_name
                        }"
                        :onSelected="
                          val => {
                            form.district_id = val.id_district;
                            getWilayah(
                              `api/subdistrict/get?id_district=${val.id_district}`,
                              'arrSubDistrict'
                            );
                          }
                        "
                      />
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Latitude</div>
                      <b-form-input
                        v-model="maps.position.lat"
                        disabled
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group>
                      <div class="input-title">Longitude</div>
                      <b-form-input
                        v-model="maps.position.lng"
                        disabled
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="7" class="pt-6">
                    <l-map
                      v-if="dialog"
                      style="height: 384px"
                      :zoom="maps.zoom"
                      :center="maps.center"
                    >
                      <l-tile-layer :url="maps.url"></l-tile-layer>
                      <l-marker :lat-lng.sync="maps.position" draggable>
                        <l-icon
                          :icon-size="[15, 15]"
                          :icon-url="`${baseAppPath}/media/circle-blue.png`"
                        />
                      </l-marker>
                    </l-map>
                  </b-col>
                </b-row>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <Editor v-if="dialog" v-model="form.desc"></Editor>
                </b-form-group>

                <button
                  ref="kt_submit"
                  :disabled="loading"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>

    <v-app
      v-if="showViewDialog"
      class="overflow-hidden"
      :style="{ height: showViewDialog ? 'inherit' : '0px' }"
    >
      <v-dialog
        v-model="showViewDialog"
        width="500px"
        style="background: #FFFFFF; !important"
        persistent
      >
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-10 pt-20">
              <div
                class="dialog-close"
                v-on:click="() => (showViewDialog = false)"
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <b-row>
                <b-col cols="12" sm="3">
                  <div
                    :style="{
                      backgroundImage: `url('${baseAppPath}/media/logos/logo-letter-1.png')`
                    }"
                    style="background-size: contain; background-position: top center;"
                  >
                    <b-aspect aspect="1:1" class="w-100"> </b-aspect>
                  </div>
                </b-col>

                <b-col cols="12" sm="9">
                  <div class="desc mt-2 mb-2">{{ selectedData.name }}</div>
                  <div class="modal-desc" v-html="selectedData.desc"></div>

                  <button
                    @click="openModule()"
                    class="btn btn-primary d-block font-weight-bolder px-6 py-3 mt-5"
                    style="border-radius: 10px"
                    v-ripple
                  >
                    Lihat Modul
                  </button>
                </b-col>
              </b-row>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";

import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";
import Editor from "@/view/components/Editor/Editor.vue";
import DatePicker from "vue2-datepicker";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-icon", LIcon);

export default {
  mixins: [validationMixin],
  props: [
    "programId",
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    Editor,
    DatePicker,
    RoleSelect
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no", width: "60px" },
        { text: "Penanggung Jawab", value: "location_pj", width: "120px" },
        { text: "Tanggal", value: "date", width: "120px" },
        { text: "Provinsi", value: "province_name", width: "120px" },
        { text: "Kota/Kabupaten", value: "district_name", width: "120px" },
        { text: "Latitude", value: "latitude", width: "120px" },
        { text: "Longitude", value: "longitude", width: "120px" },
        { text: "Deskripsi", value: "desc", width: "120px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      arrLocation: [],
      selectedData: {},

      maps: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        icon: L.icon({
          iconUrl: "/assets/marker-icon.png"
          // iconSize: [37, 37],
          // iconAnchor: [16, 37]
        }),
        zoom: 4,
        center: [-1.9450816, 113.3737863],
        position: {
          lat: -1.9450816,
          lng: 113.3737863
        }
      },
      arrProvince: [],
      arrDistrict: [],

      form: {
        id_program: this.programId,
        penanggung_jawab: null,
        id_location: null,
        lat: null,
        long: null,
        province_id: null,
        district_id: null,
        date: null,
        desc: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      showViewDialog: false
    };
  },
  validations: {
    form: {
      penanggung_jawab: {
        required,
        minLength: minLength(1)
      }
    }
  },
  mounted() {
    this.getData();
    this.getWilayah("api/province/get", "arrProvince");
  },
  methods: {
    getData() {
      let url = `api/program-location/get?token=${JwtService.getToken(
        "program"
      )}&id_program=${this.programId}`;

      ApiService.get(url, "program")
        .then(({ data }) => {
          // console.log(data)
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this.arrLocation = data.response.arrLocation;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWilayah(url, arrName) {
      ApiService.get(url, "program")
        .then(({ data }) => {
          if (data?.response?.Code == 200 || data?.response?.code == 200) {
            this[arrName] = data?.response?.[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var form = {
        ...this.form,
        lat: this.maps.position.lat + "",
        long: this.maps.position.lng + ""
      };
      var formData = {
        id_program: this.programId,
        arrLocation: [form],
        location: { ...form, date: Moment(this.form.date).format("YYYY/MM/DD") }
      };

      if (!this?.selectedData?.id_location) {
        // Create
        ApiService.post(
          `api/program-data/post?token=${JwtService.getToken("program")}`,
          formData,
          "program"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Lokasi Program berhasil ditambahkan"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.patch(
          `api/program-data/update?token=${JwtService.getToken("program")}`,
          formData,
          "program"
        )
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data Lokasi Program berhasil diperbarui"
              });
            }

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      var _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Lokasi Program?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`api/modul/${id}`, "elearning")
                .then(() => {
                  _this.getData();
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        name: null,
        province_id: null,
        district_id: null,
        subdistrict_id: null,
        village_id: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatus(isConfirm) {
      if (!isConfirm) {
        this.selectedData.active = !this.selectedData.active;
      }
      this.cofirmationDialogOpened(false);
    },
    openConfirmationDialog(item) {
      this.selectedData = item;
      this.cofirmationDialogOpened(true);
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },

    onPageChanged(page) {
      this.pagination = page;
    },
    openModule() {
      window.open(
        `${process.env.VUE_APP_ELEARNING_URL}${this.selectedData.file_location}`,
        "_blank"
      );
    }
  }
};
</script>
